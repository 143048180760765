document.addEventListener("DOMContentLoaded", () => {

    let doc = document;

    let moreProductsSlider = doc.querySelector('.slider.more-products');
    if (moreProductsSlider) {
        let items = moreProductsSlider.querySelectorAll('li');
        let containerSlider = moreProductsSlider.querySelector('ul');

        let countItems = items.length;
        let countView = 4;
        let activeIndex = 0;
        let buttons = [];
        changeConfig();

        let countButtons = Math.ceil(countItems / countView);
        // create buttons elements
        renderButtons();
        changeActiveItems(activeIndex);

        function renderButtons() {
            moreProductsSlider.querySelector('.buttons').innerHTML = '';
            for (let i = 0; i < countButtons; i++) {
                moreProductsSlider.querySelector('.buttons').appendChild(doc.createElement('button'));
            }

            buttons = moreProductsSlider.querySelectorAll('.buttons button');
            // first active
            buttons[activeIndex].classList.add('active');

            for (let i = 0; i < countButtons; i++) {
                let button = buttons[i];
                button.addEventListener('click', () => {
                    moreProductsSlider.querySelector('.buttons .active').classList.remove('active');
                    button.classList.add('active');

                    // set active index
                    activeIndex = i;
                    let vw = -100 * activeIndex;
                    containerSlider.style.left = vw + "%";
                    changeActiveItems(activeIndex);
                })
            }
        }

        function changeActiveItems(index) {
            for (let i = 0; i < countItems; i++) {
                let item = items[i];
                item.classList.remove('active');

            }
            for (let i = 0; i < countItems; i++) {
                let item = items[i];
                if (i >= activeIndex * countView && i < activeIndex * countView + countView) {
                    item.classList.add('active');
                }
            }
        }

        function changeConfig() {
            if (window.innerWidth < 767) {
                countView = 1;
            } else if (window.innerWidth < 991) {
                countView = 2;
            } else if (window.innerWidth < 1199) {
                countView = 3;
            } else {
                countView = 4;
            }
            countButtons = Math.ceil(countItems / countView);
            changeActiveItems(activeIndex);
            renderButtons();
        }

        window.addEventListener('resize', () => {
            changeConfig();
        })
    }

});


