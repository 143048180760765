document.addEventListener("DOMContentLoaded", () => {

    let doc = document;

    let imageGallery = doc.querySelector('.image-gallery');
    if (imageGallery) {
        let leftArrow = imageGallery.querySelectorAll('.left');
        let rightArrow = imageGallery.querySelectorAll('.right');
        let containerSlider = imageGallery.querySelector('ul');
        let items = imageGallery.querySelectorAll('li');
        let counter = 0;

        rightArrow.forEach((item) => {
            item.addEventListener('click', () => {
                if (Math.ceil(items.length / 2) - 1 > counter) {
                    counter++;
                    let vw = -100 * counter;
                    containerSlider.style.left = vw + "%";

                    leftArrow[0].removeAttribute('disabled');

                }
                if (!(Math.ceil(items.length / 2) - 1 > counter)) {
                    rightArrow[0].setAttribute('disabled', 'disabled');
                }
            })

        })

        leftArrow.forEach((item) => {
            item.addEventListener('click', () => {
                if (counter > 0) {
                    counter--;
                    let vw = -100 * counter;
                    containerSlider.style.left = vw + "%";
                    rightArrow[0].removeAttribute('disabled');
                }
                if (!(counter > 0)) {
                    leftArrow[0].setAttribute('disabled', 'disabled');
                }
            })
        })
    }

});
