import React from 'react';
import ReactDOM from 'react-dom';

import './js/TabbleStriped';
import './js/ProductGallery';
import './js/ProductSlider';

import ContactForm from "./components/ContactForm";
import TableForm from "./components/TableForm";


document.addEventListener("DOMContentLoaded", () => {

	let doc = document;
	let body = doc.querySelector('body');
	let html = doc.querySelector('html');


	let contactFormE = doc.getElementById('contact-form');
	if (contactFormE) {
		ReactDOM.render(<ContactForm oou={contactFormE.getAttribute('data-oou')} />, contactFormE);
	}

	let tableFormE = doc.getElementById('table-form');
	if (tableFormE) {
		ReactDOM.render(<TableForm
			product={tableFormE.getAttribute('data-product')}
			head={tableFormE.getAttribute('data-head')}
			body={tableFormE.getAttribute('data-body')}
			endpoint={tableFormE.getAttribute('data-endpoint')}
		/>, tableFormE);
	}

	let hamburger = doc.getElementById("hamburger");
	if (hamburger) {
		hamburger.addEventListener('click', () => {
			body.classList.toggle('open-menu');
		})
	}


	let cookiesLayout = doc.getElementById('cookies-layout');
	if (cookiesLayout) {
		cookiesLayout.querySelector('button').addEventListener('click', () => {
			setCookie('cookies_accept',1,365);
			cookiesLayout.remove();
		})
	}


	/* let catalogGroups = doc.querySelectorAll('.catalogs-group');
	 if (catalogGroups.length > 0) {
		 for (let i = 0; i < catalogGroups.length; i++) {
			 let group = catalogGroups[i];
			 let button = group.querySelector('button');
			 button.addEventListener('click', () => {
				 group.classList.toggle('expand');
			 });

			 let h3 = group.querySelector('h3');
			 h3.addEventListener('click', () => {
				 group.classList.toggle('expand');
			 });
		 }
	 }*/

	function resizeMasonryItem(item) {
		/* Get the grid object, its row-gap, and the size of its implicit rows */
		var grid = document.getElementsByClassName('masonry')[0],
			rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap')),
			rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));

		/*
		 * Spanning for any brick = S
		 * Grid's row-gap = G
		 * Size of grid's implicitly create row-track = R
		 * Height of item content = H
		 * Net height of the item = H1 = H + G
		 * Net height of the implicit row-track = T = G + R
		 * S = H1 / T
		 */
		var rowSpan = Math.ceil((item.querySelector('.team-group, .content').clientHeight + rowGap) / (rowHeight + rowGap));

		/* Set the spanning as calculated above (S) */
		item.style.gridRowEnd = 'span ' + rowSpan;
	}

	function resizeAllMasonryItems() {
		// Get all item class objects in one list
		var allItems = document.getElementsByClassName('masonry-brick');

		/*
		 * Loop through the above list and execute the spanning function to
		 * each list-item (i.e. each masonry item)
		 */
		for (var i = 0; i < allItems.length; i++) {
			resizeMasonryItem(allItems[i]);
		}
	}

	var masonryEvents = ['load', 'resize'];
	masonryEvents.forEach(function (event) {
		window.addEventListener(event, resizeAllMasonryItems);
	});

	let shareFbBtn = doc.querySelector('.share-btn');
	if (shareFbBtn) {
		shareFbBtn.addEventListener('click', (e) => {
				let width = 575,
					height = 400,
					left = (window.innerWidth - width) / 2,
					top = (window.innerHeight - height) / 2,
					url = shareFbBtn.getAttribute('href'),
					opts = 'status=1' +
						',width=' + width +
						',height=' + height +
						',top=' + top +
						',left=' + left;
				window.open(url, 'twitter', opts);
				e.preventDefault();
			}
		)
	}

/*	$('.list-career-items .career-item__header button').click(function () {
		var parent = $(this).parent().parent();
		parent.find('.career-item__content').slideToggle();
		parent.toggleClass('open');
	})*/
});


function setCookie(name,value,days) {
	let expires = "";
	if (days) {
		let date = new Date();
		date.setTime(date.getTime() + (days*24*60*60*1000));
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
	let nameEQ = name + "=";
	let ca = document.cookie.split(';');
	for(let i=0;i < ca.length;i++) {
		let c = ca[i];
		while (c.charAt(0)==' ') c = c.substring(1,c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
	}
	return null;
}