document.addEventListener("DOMContentLoaded", () => {
    let doc = document;

    let tableContent = doc.querySelector('.table-content');
    if (tableContent) {
        let tds = tableContent.querySelectorAll("tbody td");
        let groups = [];

        for (var i = 0; i < tds.length; i++) {
            if (tds[i].getAttribute('rowspan') != null) {
                var rspan = tds[i];
                groups.push({
                    parent: rspan.parentNode,
                    height: rspan.getAttribute('rowspan')
                });
            }
        }

        if (groups.length == 0) {
            tableContent.classList.add('stripped');
        }

        let count = 0;
        let rows = tableContent.querySelectorAll('tr');
        let dark = true;

        for (let i = 0; i < rows.length; i++) {
            let row = rows[i];
            let index = groupIndex(row);
            if (index != null && dark) {
                let group = groups[index];
                let height = parseInt(group.height);
                for (let j = i; j < i + height; j++) {
                    rows[j].classList.add('dark');
                }
                i += height - 1;
                dark = !dark;
                continue;
            }

            dark = !dark;
        }

        function groupIndex(element) {
            for (let i = 0; i < groups.length; i++) {
                let group = groups[i].parent;
                if (group == element) {
                    return i;
                }
            }
            return null;
        }
    }
});
