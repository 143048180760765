import React, {useRef, useEffect, useState} from 'react';
import ContactForm from "./ContactForm";

const TableForm = (props) => {
	let body = props.body;

	const [sent, setSent] = useState(false);
	const [sending, setSending] = useState(false);

	const form = useRef(null);
	const name = useRef(null);
	const email = useRef(null);
	const tel = useRef(null);
	const message = useRef(null);
	const modalMessageForm = useRef(null);

	const buildDom = (str) => {
		var dom = document.createElement('table');
		dom.innerHTML = str;

		let trs = dom.querySelectorAll('tr');
		for (let i = 0; i < trs.length; ++i) {
			let tr = trs[i];
			let tds = tr.querySelectorAll('td');
			for (let j = 0; j < tds.length; ++j) {
				let td = tds[j];
				let input = document.createElement('input');
				if (!td.innerText || td.innerHTML == '&nbsp;') {
					input.setAttribute('type', 'text');
					input.setAttribute('class', 'table-input');
					input.setAttribute('name', 'input[' + i + ']' + '[' + j + ']');
					input.setAttribute('placeholder', '...');
					td.innerHTML = '';
					td.appendChild(input);
				} else {
					input.setAttribute('type', 'hidden');
					input.setAttribute('name', 'input[' + i + ']' + '[' + j + ']');
					input.setAttribute('value', td.innerHTML);
					td.appendChild(input);
				}
			}
		}


		return dom;
	};

	const validateEmail = (email) => {
		let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	useEffect(() => {
		if (sent) {
			let timerOne = setTimeout(() => {
				modalMessageForm.current.classList.add('fadeOut');
			}, 2000)

			let timerTwo = setTimeout(() => {
				setSent(false);
			}, 3000)

			return () => {
				clearTimeout(timerOne);
				clearTimeout(timerTwo);
			}
		}
	}, [sent])

	const onSubmit = async (e) => {
		e.preventDefault();

		name.current.parentElement.classList.remove('error');
		void name.current.parentElement.offsetWidth;
		email.current.parentElement.classList.remove('error');
		void email.current.parentElement.offsetWidth;
		tel.current.parentElement.classList.remove('error');
		void tel.current.parentElement.offsetWidth;
		message.current.parentElement.classList.remove('error');
		void message.current.parentElement.offsetWidth;

		let valid = true;
		if (name.current.value == '') {
			name.current.parentElement.classList.add('error');
			valid = false;
		}
		if (!validateEmail(email.current.value)) {
			email.current.parentElement.classList.add('error');
			valid = false;
		}
		if (tel.current.value == '') {
			tel.current.parentElement.classList.add('error');
			valid = false;
		}

		if (message.current.value == '') {
			message.current.parentElement.classList.add('error');
			valid = false;
		}

		if (valid) {

			let formData = new FormData(form.current);
			formData.append('product', props.product);

			const response = await fetch(props.endpoint, {
				'method': 'POST',
				body: formData
			});
			const data = await response.json();
			if (data) {
				let msg = data.msg;
				let code = data.code;
				if (code == 1) {
					form.current.reset();
					setSent(true);
					setSending(false);

				} else {
					alert(msg);
				}
			}
		}

	}

	return (
		<>
			<form method="POST" ref={form} className="form-table" onSubmit={onSubmit}>
				<div className="table-content">
					<div className="table-responsive">
						<table className="table">
							<thead dangerouslySetInnerHTML={{__html: props.head}}></thead>
							<tbody dangerouslySetInnerHTML={{__html: buildDom(body).outerHTML}}></tbody>
						</table>
					</div>
				</div>
				<div className="contact-form">
					<h2>{LOCALE['TITLE_FORM']}</h2>
					<div className="row">
						<div className="form-group col-md-6">
							<label htmlFor="name" className="form-label">{LOCALE['JMÉNO A PŘÍJMENÍ']} <span
								className="require-star">*</span></label>
							<input ref={name} id="name" type="text" className="form-control" name="name"
							       placeholder="Jozef Novák" />
						</div>
						<div className="form-group col-md-6">
							<label htmlFor="phone" className="form-label">{LOCALE['TELEFON']} <span
								className="require-star">*</span></label>
							<input ref={tel} id="phone" type="text" className="form-control"
							       name="phone" placeholder="+421" />
						</div>

						<div className="form-group col-md-12">
							<label htmlFor="email" className="form-label">{LOCALE['EMAIL']} <span
								className="require-star">*</span></label>
							<input ref={email} id="email" type="text" className="form-control"
							       name="email"
							       placeholder="@" />
						</div>

						<div className="form-group col-sm-12">
							<label htmlFor="text" className="form-label">{LOCALE['ZPRÁVA']} <span
								className="require-star">*</span></label>
							<textarea ref={message} placeholder="..." className="form-control" name="text"
							          id="text"></textarea>
						</div>
					</div>
				</div>
				<button type="submit">{LOCALE['Odeslat']}</button>

			</form>
			{sent &&
			<section className="modal-message-form animated fadeIn go" ref={modalMessageForm}>
				<div className="circle-loader load-complete">
					<div className="checkmark draw"></div>
				</div>
				<h2>Ďakujeme</h2>
			</section>
			}
		</>
	);
};

export default TableForm;