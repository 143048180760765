import React, {useRef, useState, useEffect} from "react";

const ContactForm = props => {

    const [sent, setSent] = useState(false);
    const [sending, setSending] = useState(false);

    const contactForm = useRef(null);
    const checkbox = useRef(null);
    const name = useRef(null);
    const email = useRef(null);
    const tel = useRef(null);
    const message = useRef(null);
    const company = useRef(null);
    const modalMessageForm = useRef(null);
    const validateEmail = (email) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    useEffect(() => {
        if (sent) {
            let timerOne = setTimeout(() => {
                modalMessageForm.current.classList.add('fadeOut');
            }, 5000)

            let timerTwo = setTimeout(() => {
                setSent(false);
            }, 6000)

            return () => {
                clearTimeout(timerOne);
                clearTimeout(timerTwo);
            }
        }
    }, [sent])

    const handleSubmit = async (event) => {

        event.preventDefault();
        name.current.parentElement.classList.remove('error');
        void name.current.parentElement.offsetWidth;
        email.current.parentElement.classList.remove('error');
        void email.current.parentElement.offsetWidth;
        tel.current.parentElement.classList.remove('error');
        void tel.current.parentElement.offsetWidth;
        message.current.parentElement.classList.remove('error');
        void message.current.parentElement.offsetWidth;
        checkbox.current.parentElement.classList.remove('error');
        void checkbox.current.parentElement.offsetWidth;

        let valid = true;
        if (name.current.value == '') {
            name.current.parentElement.classList.add('error');
            valid = false;
        }
        if (!validateEmail(email.current.value)) {
            email.current.parentElement.classList.add('error');
            valid = false;
        }
        if (tel.current.value == '') {
            tel.current.parentElement.classList.add('error');
            valid = false;
        }

        if (message.current.value == '') {
            message.current.parentElement.classList.add('error');
            valid = false;
        }

        if (!checkbox.current.checked) {
            checkbox.current.parentElement.classList.add('error');
            valid = false
        }
        if (valid) {
            setSending(true);
            const formdata = new FormData(event.target);

            const response = await fetch('/api/contact', {
                'method': 'POST',
                body: formdata
            });
            const data = await response.json();
            if (data) {
                let msg = data.msg;
                let code = data.code;
                if (code == 1) {
                    contactForm.current.reset();
                    setSent(true);
                    setSending(false);

                } else {
                    alert(msg);
                }
            }

        }
    }

    return (
        <>
            <h2>{LOCALE['write_us']}</h2>

            <form method="post" onSubmit={handleSubmit} ref={contactForm}>
                <div className="row">
                    <div className="form-group col-md-6">
                        <label htmlFor="name" className="form-label">{LOCALE['JMÉNO A PŘÍJMENÍ']} <span className="require-star">*</span></label>
                        <input ref={name} id="name" type="text" className="form-control" name="name"
                               placeholder="Jozef Novák" />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="company" className="form-label">{LOCALE['SPOLEČNOST']}</label>
                        <input ref={company} id="company" type="text" className="form-control"
                               name="company"
                               placeholder="Spoločnosť, s.r.o." />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="phone" className="form-label">{LOCALE['TELEFON']}  <span className="require-star">*</span></label>
                        <input ref={tel} id="phone" type="text" className="form-control"
                               name="phone" placeholder="+421" />
                    </div>

                    <div className="form-group col-md-6">
                        <label htmlFor="email" className="form-label">{LOCALE['EMAIL']}  <span className="require-star">*</span></label>
                        <input ref={email} id="email" type="text" className="form-control"
                               name="email"
                               placeholder="@" />
                    </div>

                    <div className="form-group col-sm-12">
                        <label htmlFor="text" className="form-label">{LOCALE['ZPRÁVA']} <span className="require-star">*</span></label>
                        <textarea ref={message} placeholder="..." className="form-control" name="text"
                                  id="text"></textarea>
                    </div>
                </div>


                <div className="form-actions">
                    <div className="gdpr-box">
                        <label htmlFor="gdpr">
                            <input ref={checkbox} id="gdpr" type="checkbox" name="gdpr" />
                            <span className="checkmark"></span>
                            {LOCALE['Souhlasím s']} <a href={props.oou}
                               target="_blank">{LOCALE['Ochranou osobních údajů.']}</a></label>
                    </div>
                    <button disabled={sending} type="submit">
                        {sending ? '...' : LOCALE['Odeslat']}
                        <img src="/images/icon-next-white.svg" alt="" />
                    </button>
                </div>

            </form>
            {sent &&
            <section className="modal-message-form animated fadeIn go" ref={modalMessageForm}>
                <div className="circle-loader load-complete">
                    <div className="checkmark draw"></div>
                </div>
                <h2>Potvrďte odoslanie emailu správou ktorá vám prišla na email.</h2>
            </section>
            }
        </>
    );

}


export default ContactForm;
